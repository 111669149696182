<app-loading></app-loading>
<div class="container-login" *ngIf="!logueado">
    <app-login></app-login>
</div>


<div class="main-container" *ngIf="logueado">
    <nav class="noimprimir d-flex justify-content-between navbar align-items-center fixed-top" *ngIf="!insertado"><!-- Navbar -->
      <div class="d-flex">
        <!-- <div id="collapse-btn" (click)="collapseSidebar()">
          <span class="mr-5"><i class="fa fa-list"></i></span>
        </div> -->
        <a class="navbar-title" [routerLink]="['/inicio']" >
          <img src="../assets/images/Logo-OO-trans.png" alt="{{t.logooilone}}" height="40">
        </a>
      </div>

      <div class="d-flex justify-content-end">
        <div class="navbar-item">
          <a [routerLink]="['/']" routerLinkActive="router-link-active"  class="mr-3">{{t.inicio}}</a>
        </div>
        <div class="navbar-item dropdown">
          <a href="" class="mr-3 dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ usuario?.nombre || t.cargando }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a href="" class="dropdown-item" [routerLink]="['./changepass']">{{t.cambiarclave}}</a>
            <a href="" class="dropdown-item" (click)="onLogout()">{{t.cerrarsesion}}</a>
            <!--  <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a> -->
          </div>
        </div>

        <div class="navbar-item mx-2 d-inline">
          <input type="checkbox"  id="customSwitch1" [(ngModel)]="idioma" name="idioma" (change)="cambiaIdioma()" hidden>
          <label  for="customSwitch1" style="cursor: pointer;">
            <img src="../assets/images/usa2.png" alt="{{t.seleccionaridioma}}" class="bandera flag-hidden" height="20">
            <img src="../assets/images/arg2.png" alt="{{t.seleccionaridioma}}" height="20" class="bandera">
          </label>
        </div>
      </div>
    </nav><!-- Fin de navbar -->

    <div class="sidebar-container d-flex">

      <div id="sidebar" class="sidebar-collapsed noimprimir" *ngIf="!insertado">
        <div class="d-flex justify-content-between">
          <span>{{t.modulos}}</span>
          <span class="colapse-side-btn"  (click)="collapseSidebar()"><i class="fas fa-arrow-left"></i></span>
        </div>
        <div class="dropdown-divider"></div>

        <div class="accordion" id="accordionExample"><!-- Accordion -->


          <!-- Acceso a modulo BackOffice -->
              <a href="" *ngIf="_authSvc.tienePermiso('admin_abm')" class="nav-link nav-link-sm" routerLinkActive="router-link-active" id="headingBack"  data-toggle="collapse" data-target="#collapseBack" aria-expanded="true" aria-controls="collapseBack"  ><img src="assets/images/logo-backoffice.png" alt="" height="30"><strong class="ml-2">{{t.backoffice}}</strong></a>

              <div id="collapseBack" class="collapse collapse-links" aria-labelledby="headingBack" data-parent="#accordionExample">
                <div *ngTemplateOutlet="backoffice"></div>
              </div><!-- Fin Acceso a modulo BackOffice -->


        <!-- Acceso a modulo General -->
            <!-- <a href="" class="nav-link nav-link-sm" routerLinkActive="router-link-active" id="headingGral"  data-toggle="collapse" data-target="#collapseGral" aria-expanded="true" aria-controls="collapseGral"  ><i class="fas fa-cogs mr-2"></i>{{t.general}}</a> -->
            <a href="" *ngIf="_authSvc.tienePermiso('general_abm')"  class="nav-link nav-link-sm" routerLinkActive="router-link-active" id="headingGral"  data-toggle="collapse" data-target="#collapseGral" aria-expanded="true" aria-controls="collapseGral"  ><img src="assets/images/logo-general-cog.png" alt="" height="30"><strong class="ml-2">{{t.general}}</strong></a>

            <div id="collapseGral" class="collapse collapse-links" aria-labelledby="headingGral" data-parent="#accordionExample">
                <div *ngTemplateOutlet="general"></div>
            </div><!-- Fin Acceso a modulo General -->


        <!-- Acceso a modulo Parte Diario -->
            <a href=""
              *ngIf="_authSvc.tienePermiso('parte_abm', 'parte_ver', 'parte_crear')"
              class="nav-link nav-link-sm" id="headingParte"  data-toggle="collapse" data-target="#collapseParte" aria-expanded="true" aria-controls="collapseParte"  ><img src="assets/images/Logo-PARTOne-fondo-claro.png" alt="" height="21"></a>

            <div id="collapseParte" class="collapse collapse-links" aria-labelledby="headingParte" data-parent="#accordionExample">
                <div *ngTemplateOutlet="parte"></div>
            </div><!-- Fin Acceso a modulo Parte Diario -->

        <!-- Acceso a modulo Control Documental -->
            <a href=""
              *ngIf="_authSvc.tienePermiso('docu_abm', 'docu_ver', 'docu_crear')"
              class="nav-link nav-link-sm" id="headingControl"  data-toggle="collapse" data-target="#collapseControl" aria-expanded="true" aria-controls="collapseControl"  ><img src="assets/images/Logo-DOCOne-fondo-claro.png" alt="" height="21"></a>

            <div id="collapseControl" class="collapse collapse-links" aria-labelledby="headingControl" data-parent="#accordionExample">
                <div *ngTemplateOutlet="control" ></div>
            </div><!-- Fin Acceso a modulo Control Documental -->

        <!-- Acceso a modulo CheckList -->
            <a
              *ngIf="_authSvc.tienePermiso('check_abm', 'check_ver', 'check_crear')"
              href="" class="nav-link nav-link-sm" id="headingCheck"  data-toggle="collapse" data-target="#collapseCheck" aria-expanded="true" aria-controls="collapseCheck"  ><img src="assets/images/Logo-CHECKOne-fondo-claro.png" alt="" height="20"></a>

            <div id="collapseCheck" class="collapse collapse-links" aria-labelledby="headingCheck" data-parent="#accordionExample">
                <div *ngTemplateOutlet="check" ></div>
            </div><!-- Fin Acceso a modulo CheckList -->

        <!-- Acceso a modulo Mantenimiento -->
            <a href=""
              *ngIf="_authSvc.tienePermiso('mant_ver_sol', 'mant_crear_sol', 'mant_ver_ot', 'mant_crear_ot', 'mant_vista_plan', 'mant_vista_solic', 'mant_vista_seguim', 'mant_ver_estado', 'mant_crear_medicion')"
              class="nav-link nav-link-sm" id="headingMant"  data-toggle="collapse" data-target="#collapseMant" aria-expanded="true" aria-controls="collapseMant"  ><img src="assets/images/Logo-EAMOne-fondo-claro.png" alt="" height="30"></a>

            <div id="collapseMant" class="collapse collapse-links" aria-labelledby="headingMant" data-parent="#accordionExample">
                <div *ngTemplateOutlet="mantenimiento" ></div>
            </div><!-- Fin Acceso a modulo Mantenimiento -->

        </div><!-- Fin de accordion -->

      </div>

      <div class="small-sidebar noimprimir" id="small-sidebar" *ngIf="!insertado">
        <span class="colapse-side-btn"  (click)="collapseSidebar()"><i class="fas fa-arrow-right"></i></span>
        <div class="dropdown-divider"></div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('admin_abm')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.backoffice}}">
            <!-- <i class="fas fa-cog mr-2"></i> -->
            <img src="assets/images/logo-backoffice.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.backoffice}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="backoffice"></div>
          </div>
        </div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('general_abm')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.general}}">
            <!-- <i class="fas fa-cogs mr-2"></i> -->
            <img src="assets/images/logo-general-cog.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.general}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="general"></div>
          </div>
        </div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('parte_abm', 'parte_ver', 'parte_crear')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.partediario}}">
            <!-- <i class="fas fa-file-invoice mr-2"></i> -->
            <img src="assets/images/Logo-PARTOne-fondo-claro-chico.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.partediario}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="parte"></div>
          </div>
        </div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('docu_abm', 'docu_ver', 'docu_crear')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.controldocumental}}">
            <img src="assets/images/Logo-DOCOne-fondo-claro-chico.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.controldocumental}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="control"></div>
          </div>
        </div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('check_abm', 'check_ver', 'check_crear')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.checklist}}">
            <!-- <i class="fas fa-clipboard-check mr-2"></i> -->
            <img src="assets/images/Logo-CHECKOne-fondo-claro-chico.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.checklist}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="check"></div>
          </div>
        </div>

        <div class="btn-group dropright" *ngIf="_authSvc.tienePermiso('mant_ver_sol', 'mant_crear_sol', 'mant_ver_ot', 'mant_crear_ot', 'mant_vista_plan', 'mant_vista_solic', 'mant_vista_seguim', 'mant_ver_estado', 'mant_crear_medicion')">
          <span class=" dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{t.mantenimiento}}">
            <!-- <i class="fas fa-tools"></i> -->
            <img src="assets/images/Logo-EAMOne-fondo-claro-chico.png" alt="" height="30">
          </span>
          <div class="dropdown-menu shadow-lg">
            <h5 class="ml-3">{{t.mantenimiento}}</h5>
            <div class="dropdown-divider"></div>
            <div *ngTemplateOutlet="mantenimiento"></div>
          </div>
        </div>
      </div><!-- Fin de small-sidebar -->

      <div class="content ">
        <div class="content-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <!-- <div class="dropdown-divider"></div> -->

  </div>


  <!-- **********************  LINKS DE OPCIONES MENU SIDEBAR ********************** -->
  <ng-template #backoffice>
    <a class="nav-link nav-link-sm" [routerLink]="['./backoffice/inquilinos']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.minquilinos}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./backoffice/usuarios']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.musuarios}}</a>
    <!-- <a class="nav-link nav-link-sm" [routerLink]="['./backoffice/clientes']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mclientes}}</a> -->
    <a class="nav-link nav-link-sm" [routerLink]="['./backoffice/perfiles']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mperfiles}}</a>
    <a *ngIf="_authSvc.tienePermiso('admin_ver_seg_tareas')" class="nav-link nav-link-sm" [routerLink]="['./backoffice/seg-tareas']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.msegtareas}}</a>
  </ng-template>

  <ng-template #general>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/articulo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.marticulo}}</a>
    <!-- <a class="nav-link nav-link-sm" [routerLink]="['./general/grupo-articulo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mgrupoarticulo}}</a> -->
    <a class="nav-link nav-link-sm" [routerLink]="['./general/socio-negocio']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.msocionegocio}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/tipo-socio-negocio']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtiposocionegocio}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/empleado']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mempleado}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/tipo-empleado']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtipoempleado}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/ubicacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mubicacion}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/socio-negocio-ubicacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.msocionegocioubicacion}}</a>
    <a *ngIf="_authSvc.tienePermiso('general_ver_herramientas')" class="nav-link nav-link-sm" [routerLink]="['./general/herramienta']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mherramienta}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/equipo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mequipo}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/criticidad']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.mcriticidad}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/activo-fijo']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.mactivofijo}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/tipo-equipo']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.mtipoequipo}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./general/estado-equipo']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.mestadoequipo}}</a>
    <a *ngIf="_authSvc.tienePermiso('general_ver_tablas')" class="nav-link nav-link-sm" [routerLink]="['./general/tabla-generica']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.mtablas}}</a>
    <a *ngIf="_authSvc.tienePermiso('general_sync_ver_logs')" class="nav-link nav-link-sm" [routerLink]="['./general/sync']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.msyncprocesos}}</a>
    <a *ngIf="_authSvc.tienePermiso('general_import_data')" class="nav-link nav-link-sm" [routerLink]="['./general/sync/datos-fuente']" routerLinkActive="router-link-active" ><i class="fa fa-minus mr-2"></i>{{t.importardatosmaestros}}</a>
  </ng-template>

  <ng-template #parte >
    <a class="nav-link nav-link-sm" [routerLink]="['./parte/dash']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.tablerocontrol}}</a>
    <a *ngIf="_authSvc.tienePermiso('parte_abm')" class="nav-link nav-link-sm" [routerLink]="['./parte/tarea-parte']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtarea}}</a>
    <a *ngIf="_authSvc.tienePermiso('parte_abm')" class="nav-link nav-link-sm" [routerLink]="['./parte/campo-usuario']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mcampousuario}}</a>
    <a *ngIf="_authSvc.tienePermiso('parte_abm')" class="nav-link nav-link-sm" [routerLink]="['./parte/grupo-campo-usuario']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mgrupocampousuario}}</a>
    <a *ngIf="_authSvc.tienePermiso('parte_abm')" class="nav-link nav-link-sm" [routerLink]="['./parte/modelo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.modelosparte}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['/parte/parte']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.partediario}}</a>
  </ng-template>

  <ng-template #control >
    <a class="nav-link nav-link-sm" [routerLink]="['./controldocumental/dash']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.tablerocontrol}}</a>
    <a *ngIf="_authSvc.tienePermiso('docu_abm')" class="nav-link nav-link-sm" [routerLink]="['./controldocumental/tipo-documentacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtipodocumentacion}}</a>
    <a *ngIf="_authSvc.tienePermiso('docu_abm')" class="nav-link nav-link-sm" [routerLink]="['./controldocumental/recurso-documentacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mrecursodocumentacion}}</a>
    <a *ngIf="_authSvc.tienePermiso('docu_abm')" class="nav-link nav-link-sm" [routerLink]="['./controldocumental/socio-negocio-documentacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.msocionegociodocumentacion}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./controldocumental/documentacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mdocumentacion}}</a>
  </ng-template>

  <ng-template #check >
    <a class="nav-link nav-link-sm" [routerLink]="['./check/dash']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.tablerocontrol}}</a>
    <a *ngIf="_authSvc.tienePermiso('check_abm')" class="nav-link nav-link-sm" [routerLink]="['./check/componente-check']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mcomponentecheck}}</a>
    <a *ngIf="_authSvc.tienePermiso('check_abm')" class="nav-link nav-link-sm" [routerLink]="['./check/modelo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mmodelochecklist}}</a>
    <a class="nav-link nav-link-sm" [routerLink]="['./check/check']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.checklist}}</a>
  </ng-template>

  <ng-template #mantenimiento >
    <a class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/dash']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.tablerocontrol}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_vista_seguim')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/solicitud-mantenimiento/vista/seguimiento']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.seguimientosolicitudesmantenimiento}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_vista_plan')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/solicitud-mantenimiento/vista/planificador']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.solicitudesmantenimiento}} - {{t.planificacion}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_vista_solic')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/solicitud-mantenimiento/vista/solicitante']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.solicitudesmantenimiento}} - {{t.solicitante}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_vista_plan')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/orden-trabajo/vista/planificador']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.ordenestrabajo}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_vista_solic')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/orden-trabajo/vista/solicitante']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.ordenestrabajo}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_crear_medicion')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/medicion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mediciones}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_crear_medicion')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/estado-equipo']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.estadoequipo}}</a>
    <div class="dropdown-divider" *ngIf="_authSvc.tienePermiso('mant_ver_ot') || _authSvc.tienePermiso('mant_abm')"></div>
    <a *ngIf="_authSvc.tienePermiso('mant_ver_ot')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/costomantenimiento']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reportecostomantenimiento}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/historialubicacion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reportehistorialubicacion}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/informe-equipos']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reporteequipos}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/disponibilidad-equipos']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reportedisponibilidadequipos}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/reporte-ot']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reporteordentrabajo}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/reportes/reporte-ot/pendiente']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.reportetareaspendientes}}</a>
    <div class="dropdown-divider"></div>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/plan-mantenimiento']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.planmantenimiento}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/articulo-previsto']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.marticuloprevisto}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/rrhh-previsto']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mrrhhprevisto}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/tarea-prevista']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtareaprevista}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/prioridad']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mprioridad}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/tipo-mantenimiento']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtipomantenimiento}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/tipo-medicion']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtipomedicion}}</a>
    <a *ngIf="_authSvc.tienePermiso('mant_abm')" class="nav-link nav-link-sm" [routerLink]="['./mantenimiento/tarea']" routerLinkActive="router-link-active"  ><i class="fa fa-minus mr-2"></i>{{t.mtarea}}</a>
  </ng-template>
