<div class="center">
    <ngx-file-drop dropZoneLabel="{{t[leyenda]}}" (onFileDrop)="dropped($event)" >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="text-center" style="color: #222;">
                {{t[leyenda]}}<br>
                <button type="button" (click)="openFileSelector()" class="btn btn-secondary">{{t.buscararchivos}}</button>
            </div>
        </ng-template>
    </ngx-file-drop>
</div>
