import { TituloSortComponent } from './titulo-sort/titulo-sort.component';
import { TextoFiltroComponent } from './texto-filtro/texto-filtro.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { FiltroGeneralPipe } from './pipes/filtro-general.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoadingComponent } from './loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectorComponent } from './selector/selector.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FiltroBasePipe } from './pipes/filtro-base.pipe';
import { OrderBasePipe } from './pipes/order-base.pipe';
import { IndicadorComponent } from './indicador/indicador.component';
import { RouterModule } from '@angular/router';
import { GraficoComponent } from './Grafico/grafico.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { formatNumber } from './pipes/formatNumber.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { GroupboxComponent } from './groupbox/groupbox.component';
import { FormgroupComponent } from './formgroup/formgroup.component';


@NgModule({
  declarations: [
    ButtonComponent,
    UploadFileComponent,
    SelectorComponent,
    BuscadorComponent,
    TextoFiltroComponent,
    TituloSortComponent,
    LoadingComponent,
    FiltroBasePipe,
    FiltroGeneralPipe,
    OrderBasePipe,
    OrderByPipe,
    IndicadorComponent,
    GraficoComponent,
    formatNumber,
    GroupboxComponent,
    FormgroupComponent
  ],
  imports: [
    CommonModule,
    NgxFileDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HighchartsChartModule,
    NgSelectModule,
    CollapseModule
  ],
  exports: [
    ButtonComponent,
    UploadFileComponent,
    SelectorComponent,
    BuscadorComponent,
    TextoFiltroComponent,
    TituloSortComponent,
    LoadingComponent,
    FiltroBasePipe,
    FiltroGeneralPipe,
    OrderBasePipe,
    OrderByPipe,
    IndicadorComponent,
    GraficoComponent,
    NgSelectModule,
    GroupboxComponent,
    FormgroupComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
