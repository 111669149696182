import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroBase'
})
export class FiltroBasePipe implements PipeTransform {

  filtrobool(valor: boolean, filtro: string): boolean {
    const txt = valor ? 'si' : 'no';
    return txt.includes(filtro.toLowerCase());
  }

  transform(value: any, codigo: string, nombre: string, descripcion: string, activo: string): any {

    if (codigo) {  value = value.filter(item => item.codigo.toLowerCase().includes(codigo.toLocaleLowerCase())); }
    if (nombre) {  value = value.filter(item => item.nombre.toLowerCase().includes(nombre.toLocaleLowerCase())); }
    if (descripcion) {  value = value.filter(item => item.descripcion.toLowerCase().includes(descripcion.toLocaleLowerCase())); }

    if (activo) {
      value = value.filter(item => this.filtrobool(item.activo, activo));
    }

    return value;
  }

}
