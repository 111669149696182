import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdiomaService } from 'src/app/services/idioma.service';

@Component({
  selector: 'app-formgroup',
  templateUrl: './formgroup.component.html',
  styleUrls: ['./formgroup.component.css']
})
export class FormgroupComponent implements OnInit {

  public t: any;
  public textos$: Observable<any>;

  @Input() item: FormGroup;
  @Input() itemName: string = '';

  constructor(
    private _idioma: IdiomaService
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });
  }

}
