<div class="text-left">

    <!-- <a routerLink="rutaNuevo" class="btn btn-primary btn-sm mr-2">{{t.nuevo}}</a> -->
    <button *ngIf="botonCrear" (click)="onCreate()" title="{{t.crearnuevo}}" class="btn btn-primary btn-sm mr-2">{{t.nuevo}}</button>
    <button *ngIf="botonEditar" (click)="onEdit()"  title="{{t.selecionaritem}}"  class="btn btn-primary btn-sm mr-2" [disabled]="!id">{{t.editar}}</button>
    <div class="btn-group dropleft" *ngIf="botonEliminar">
        <button title="{{t.selecionaritem}}" [disabled]="!id" class="btn btn-danger btn-sm mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-trash"></i></button>
        <div class="dropdown-menu">
            <h6 class="text-center">
                {{t.seguroelimina}}
            </h6>
            <div class="d-flex justify-content-around">
                <button class="btn btn-danger btn-sm mx-2"  (click)="onDelete()"  title="{{t.selecionaritem}}">{{t.eliminar}}</button>
                <button class="btn btn-secondary btn-sm mx-2">{{t.cancelar}}</button>
            </div>
        </div>
    </div>
</div>
