import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdjuntoService {
  protected headers: any;

  private rutaRelativa = 'nivel3/adjuntos';

  constructor(
    protected http: HttpClient,
    protected authService: AuthenticationService
  ) {

    // Para enterarme de cualquier cambio de token (previsión para renovación automática de token al expirar la sesión).
    this.authService.cambioUsuario$.subscribe(user => {
      if (user == null) {
        this.headers = {
        };
      } else {
        this.headers = {
          authorization: 'Bearer ' + user.token
        };
      }
    });
  }

  public getById(modulo: string, id_objeto: string, nombre: string, id_archivo: string): void { // Observable<any> {

    const url = environment.apiUrl + this.rutaRelativa + '/' + modulo + '/' + id_objeto + '/' + id_archivo;
    this.http.get<Blob>(url, { headers: this.headers, responseType: 'blob' as 'json' }).subscribe((data) => {
      const blob = new Blob([data], {type: 'image/jpg'});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = nombre;
      link.click();
    });
  }

  public getByIdURL(modulo: string, id_objeto: string, nombre: string): string {
    return environment.apiUrl + this.rutaRelativa + '/' + modulo + '/' + id_objeto + '/' + nombre;
  }

  public create(modulo: string, id_objeto: string, nombrearchivo: string, contenido: any): Observable<any> {
    const formData = new FormData();
    formData.append('archivos', contenido, nombrearchivo);
    return this.http.post(environment.apiUrl + this.rutaRelativa + '/' + modulo + '/' + id_objeto, formData, {headers: this.headers});
  }

  public destroy(modulo: string, id_objeto: string): Observable<any> {
    return this.http.delete(environment.apiUrl + this.rutaRelativa + '/' + modulo + '/' + id_objeto, { headers: this.headers });
  }

}
