<ng-select style="min-width: 200px;" class="custom"
  [items]="itemsCombo"
  [formControl]="control"
  bindLabel="nombre"
  bindValue="{{bindValue}}"
  appendTo="body"></ng-select>

<!--
<input class="form-control form-control-sm" type="text" [attr.list]="'itemsbuscador' + this.idinterno"  (blur)="onBlur()" [formControl]="control" autocomplete="off" />
<datalist [id]="'itemsbuscador' + this.idinterno">
  <option *ngFor="let item of items">{{descripcionItem(item)}}</option>
</datalist>
-->
