import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../../services/authentication.service';
import { BaseService } from './../../services/baseService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Usuario } from '../models/usuario';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseService<Usuario> {

  constructor(
    http: HttpClient,
    authService: AuthenticationService,
  ) {
    super(http, authService, 'nivel1/usuarios');
  }

  login(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'nivel1/usuarios/login', data, { headers: this.headers });
  }

  changepass(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'nivel1/usuarios/changepass', data, { headers: this.headers });
  }

  resetpass(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'nivel1/usuarios/resetpass', data, { headers: this.headers });
  }

  generarClave(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'nivel1/usuarios/createkey', data, { headers: this.headers });
  }

  public logout(): void {
    this.authService.logout();
  }
}
