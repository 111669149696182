import { IdiomaService } from 'src/app/services/idioma.service';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/backoffice/services/usuario.service';
import { FormGroup, FormControl, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.css']
})
export class ChangepassComponent implements OnInit {

  public t: any;
  public textos$: Observable<any>;

  public loginForm: FormGroup;

  constructor(
    private _usuarioSvc: UsuarioService,
    private _router: Router,
    private _idioma: IdiomaService
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => this.t = texto);

    this.loginForm = new FormGroup({
      passwordactual: new FormControl('', Validators.required),
      passwordnueva: new FormControl('', Validators.required),
      passwordnueva2: new FormControl('', Validators.required),
    }, { validators: this.claveValida });
  }

  onSubmit(): void {

    this._usuarioSvc.changepass(this.loginForm.value).subscribe(
      resp => {
        this._router.navigate(['/']);
      }, error => {
        console.log(error);
      }
    );
  }

  claveValida(control: AbstractControl): ValidationErrors | null {
    const passwordnueva = control.get('passwordnueva').value;
    const passwordnueva2 = control.get('passwordnueva2').value;

    if (passwordnueva !== passwordnueva2) {
      return { nocoinciden: true };
    }

    return null;
  }
}


