import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-titulo-sort',
  templateUrl: './titulo-sort.component.html',
  styles: []
})
export class TituloSortComponent implements OnInit {

  @Input() campo: string;

  @Input() orderColumn: string;
  @Input() orderDirection: string;

  @Output() sortData = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
