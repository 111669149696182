import { LoadingScreenInterceptor } from './services/loading.intercetor';
import { ErrorInterceptor } from './services/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* Modules */
import { LoginModule } from './login/login.module';

/* Components */
import { AppComponent } from './app.component';
import { PortadaComponent } from './portada/portada.component';
import { IdiomaService } from './services/idioma.service';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChangepassComponent } from './changepass/changepass.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';

/* Services */
import { ExportExcelService } from './services/export-excel.service';


@NgModule({
  declarations: [
    AppComponent,
    PortadaComponent,
    ChangepassComponent,
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoginModule,
    SharedModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', timeOut: 3000}),
    ModalModule.forRoot(),
    CollapseModule.forRoot()
  ],
  exports: [SharedModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
    IdiomaService,
    ExportExcelService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
