import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatNumber'})
export class formatNumber implements PipeTransform {
  transform(value: number): string {
    let nuevoNumero = [];
    let [parteEntera, parteDecimal = '00'] = value.toString().split('.');

    while (parteEntera.length){
      nuevoNumero.push(parteEntera.substring(parteEntera.length - 3, parteEntera.length));
      parteEntera = parteEntera.substring(0, parteEntera.length - 3);
    }

    let resp = `${nuevoNumero.reverse().join('.')},${parteDecimal}`;
    return resp

  }
}
