import { Observable } from 'rxjs';
import { Indicador } from './../models/indicador';
import { Component, Input, OnInit } from '@angular/core';
import { IdiomaService } from 'src/app/services/idioma.service';

@Component({
  selector: 'app-indicador',
  templateUrl: './indicador.component.html',
  styleUrls: ['./indicador.component.css']
})
export class IndicadorComponent implements OnInit {

  @Input() public codigo: string;

  @Input() public indicador: Indicador;

  public ruta: string[];
  public nombre: string;
  public unidadMedida: string;
  public t: any;
  public textos$: Observable<any>;

  constructor(
    private _idioma: IdiomaService,
  ) { }


  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });
    console.log('INDICADOR', this.indicador);

    this.ruta = [this.indicador.ruta];
    this.nombre = this.t[this.indicador.nombre];
    this.unidadMedida = this.indicador.unidadMedida ? this.t[this.indicador.unidadMedida] : this.t.unidades
    if(this.indicador.parametro != ''){
      this.ruta.push(this.indicador.parametro);
    }
  }

}
