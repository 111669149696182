import { IdiomaService } from 'src/app/services/idioma.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'filtroGeneral'
  // , pure: false
})
export class FiltroGeneralPipe implements PipeTransform {

  constructor(
    private _idioma: IdiomaService,
  ) {
  }

  obtenerValor(v: any, campo: string): any {
    let resultado = v;

    const partes = campo.split('$');

    for (const p of partes) {
      if (typeof(resultado) === 'undefined' || resultado === null) {
        break;
      }

      resultado = resultado[p];
    }

    if (typeof(resultado) === 'undefined' || resultado === null) {
      resultado = '';
    }

    if (typeof(resultado) === 'boolean') {
      resultado = resultado ? this._idioma.getTexto('si') : this._idioma.getTexto('no');
      resultado = resultado.toLowerCase();
    }

    // Habilito obtecion de valores a partir de un array de objetos
    if(Array.isArray(resultado)){
      resultado = resultado.map(({id, cadena, ...elem}) => {
        if(cadena) return cadena;
        return Object.values(elem).join(' ')
      }).join(' ');
    }

    if (isNaN(resultado) && typeof(resultado.toLowerCase) === 'function') {
      resultado = resultado.toLowerCase();
    }

    if(typeof(resultado) !== 'number')
      resultado = this._idioma.getTexto(resultado)?.toLowerCase() || resultado.toLowerCase();

    return '' + resultado;
  }

  transform(value: any, filtro: any): any {

    // console.log('filtro', filtro);

    for (const key in filtro) {
      if (Object.prototype.hasOwnProperty.call(filtro, key)) {
        let element = filtro[key];
        if (element !== '') {

          element = element.toLocaleLowerCase();

          const formatoFecha = this._idioma.getTexto('formatofecha').toUpperCase();
          if (key.startsWith('fecha')) {
            element = element.replace('/', '-');
          }

          value = value.filter(item => {
            if (key.endsWith('$nombre')) {
              const jj = this.obtenerValor(item, key).includes(element)
                  || this.obtenerValor(item, key.replace('$nombre', '$codigo')).includes(element);
              return jj;
            }
            else if (key.startsWith('fecha')) {
              const jj = moment(item[key], 'YYYY-MM-DD').format(formatoFecha).includes(element);
              return jj;
            }
            else {
              const jj = this.obtenerValor(item, key).includes(element);
              return jj;
            }
          });
        }
      }
    }

    return value;
  }

}
