import { Empleado } from './../../general/models/empleado';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModeloBase } from 'src/app/abm/models/modelobase';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuscadorComponent),
      multi: true
    }
  ]
})
export class BuscadorComponent implements OnInit, ControlValueAccessor {

  items: ModeloBase[];
  itemsCombo: any[];

  control: FormControl;

  @Output() seleccionado = new EventEmitter<ModeloBase>();

  @Input() required: boolean;

  @Input() bindValue : string = "id";

  @Input()
  set datos(value: ModeloBase[]) {
    if (value) {
      this.itemsCombo = value.map(a => {
        if(a && a.hasOwnProperty('activo') && !a.activo)
          return;

        return {
          [this.bindValue] : this.bindValue != "id" && a[this.bindValue],
          id: a.id,
          nombre: this.descripcionItem(a)
        };
      }).filter(item => item);
    }

    this.items = value;

    // Falta informar?
    if (this.control.value) {
      const valor = this.control.value;
      const encontrado = this.items?.filter(a => '' + a[this.bindValue] === '' + valor);
      if (encontrado && encontrado.length && encontrado.length > 0) {
        if(encontrado[0] && encontrado[0].hasOwnProperty('activo') && !encontrado[0].activo){
          this.itemsCombo = [
            {
              [this.bindValue] : this.bindValue != "id" && encontrado[0][this.bindValue],
              id: encontrado[0].id,
              nombre: this.descripcionItem(encontrado[0])
            },
            ...this.itemsCombo
          ];
        }

        this.seleccionado.emit(encontrado[0]);
      }
    }
  }

  @Input()
  get disabled(): boolean { return this.control.disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    }
    else {
      this.control.enable();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor() {
    this.control = new FormControl();

    this.control.valueChanges.subscribe(valor => {

      // Buscar el ID y asignarlo.
      if (this.items && this.items.length) {
        if (valor) {
          const encontrado = this.items.filter(a => '' + a[this.bindValue] === '' + valor);
          if(encontrado[0] && encontrado[0].hasOwnProperty('activo') && !encontrado[0].activo){
            this.itemsCombo = [
              {
                [this.bindValue] : this.bindValue != "id" && encontrado[0][this.bindValue],
                id: encontrado[0].id,
                nombre: this.descripcionItem(encontrado[0])
              },
              ...this.itemsCombo
            ];
          }

          this.seleccionado.emit(encontrado[0]);
        }
        else {
          this.seleccionado.emit(null);
        }
      }
    });
  }

  ngOnInit(): void {
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  @Input()
  public get value(): string | null {
    return this.control.value;
  }

  public set value(valor: string | null) {
    if (valor === '') {
      valor = null;
    }

    this.control.setValue(valor);
  }

  public descripcionItem(it: ModeloBase): string {
    if (it.hasOwnProperty('apellidos') && it.hasOwnProperty('nombres')) {
      const e = it as Empleado;
      return it.codigo + ': ' + e.apellidos + ', ' + e.nombres;
    }
    else {
      return it.codigo + ': ' + it.nombre;
    }
  }

  //////////////////////////////////
  // ControlValueAccessor interface
  //////////////////////////////////

  writeValue(opt: string | null): void {
    this.value = opt;
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
