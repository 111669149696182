import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { IdiomaService } from 'src/app/services/idioma.service';
import { FormArray  } from '@angular/forms';

@Component({
  selector: 'app-groupbox',
  templateUrl: './groupbox.component.html',
  styleUrls: ['./groupbox.component.css']
})
export class GroupboxComponent implements OnInit {

  public t: any;
  public textos$: Observable<any>;
  public isCollapsed = true;

  @Input() items: FormArray;
  @Input() group: any;


  constructor(
    private _idioma: IdiomaService,
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

}
