import jwt_decode from 'jwt-decode';
import { UsuarioLogueado } from './../backoffice/models/usuariologueado';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Usuario } from '../backoffice/models/usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private usuarioLogueado: BehaviorSubject<UsuarioLogueado>;

  public cambioUsuario$: Observable<UsuarioLogueado>;

  constructor() {
    this.usuarioLogueado = new BehaviorSubject<UsuarioLogueado>(JSON.parse(localStorage.getItem('OOUser')));
    this.cambioUsuario$ = this.usuarioLogueado.asObservable();
  }

  public get usuarioActual(): UsuarioLogueado {
    return this.usuarioLogueado.value;
  }

  public logout(): void {
    localStorage.setItem('OOUser', null);
    this.usuarioLogueado.next(null);
  }

  public tienePermiso(... permisos: string[]): boolean {
    for (const permiso of permisos) {
      if (this.usuarioLogueado.value.permisos.indexOf(permiso) !== -1) {
        return true;
      }
    }

    return false;
  }

  public validarNuevoJwt(jwt: string): boolean {

    if (jwt == null || jwt === '' || Object.entries(jwt).length === 0) {
      return false;
    } else {
      const dec: any = jwt_decode(jwt);

      const usuarioNuevo =
      {
        token: jwt,
        usuario: dec.usuario,
        id: dec.id,
        inquilino: dec.inquilino,
        perfil: dec.perfil,
        issuedAt: dec.iat,
        expiresAt: dec.exp,
        permisos: dec.permisos.split(',').filter(a => a != '')
      };

      localStorage.setItem('OOUser', JSON.stringify(usuarioNuevo));
      this.usuarioLogueado.next(usuarioNuevo);

      return true;
    }
  }

}
