<div class="login-container">
  <div class="d-flex justify-content-center align-items-center h-100">
      <div class="login-form-container">
          <div class="login-form-inner-container">
              <div class="login-form-content">
                  <div class="login-form-data">
                      <form [formGroup]="loginForm" (submit)="onSubmit()">

                          <div class="login-form-group d-flex justify-content-between flex-wrap align-items-center">
                              Clave actual
                              <input type="password" id="nombre" class="form-control" formControlName="passwordactual" >
                          </div>

                          <div class="login-form-group d-flex justify-content-between flex-wrap align-items-center">
                              Clave nueva
                              <input type="password" class="form-control" formControlName="passwordnueva" >
                          </div>

                          <div class="login-form-group d-flex justify-content-between flex-wrap align-items-center">
                            Reingrese la clave nueva
                            <input type="password" class="form-control" formControlName="passwordnueva2" >
                          </div>

                          <div class="login-form-group-btn text-right">
                              <button type="submit" class="btn btn-outline-warning text-dark" [disabled]="!loginForm.valid" icon="login">Cambiar clave</button>

                          </div>
                      </form>
                  </div>
              </div>
          </div><!-- End Login-form-inner-container -->

      </div>

  </div>


</div>
