import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IdiomaService } from '../services/idioma.service';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.css']
})
export class PortadaComponent implements OnInit {

  public t: any;
  public textos$: Observable<any>;
  public title: string;

  constructor(
    private _idioma: IdiomaService,
    public _authSvc: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
      this.title = this.t.tablerocontrol;
    });
  }
}
