import { AuthenticationService } from './services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UsuarioService } from './backoffice/services/usuario.service';

import { IdiomaService } from './services/idioma.service';
import {idioma} from './services/textos';

import { Observable } from 'rxjs';
import { Usuario } from './backoffice/models/usuario';
import { links } from './services/menu-link';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ UsuarioService, IdiomaService]
})
export class AppComponent implements OnInit {

  public t: any;
  public idioma: boolean;
  public textos$: Observable<any>;
  public logueado: boolean;
  public collapsed: boolean = false;
  public usuario: Usuario;
  public menu: any;
  public insertado = false; // para usar insertado en SAPB1 cambiar a true

  public moduleTitle: string = 'Nombre del módulo';

  constructor(
    private _idiomaSvc: IdiomaService,
    public _authSvc: AuthenticationService,
    private _router: Router,
    private _usuarioSvc: UsuarioService,
    private _activeRoute: ActivatedRoute,
  ){
  }

  ngOnInit(): void {
    console.log('app.ngOnInit');
    console.log('this._activeRoute.snapshot.queryParamMap', this._activeRoute.snapshot.queryParamMap);
    this.menu = links;
    this.textos$ = this._idiomaSvc.getTextos$();
    this.textos$.subscribe(texto => this.t = texto);

    this._authSvc.cambioUsuario$.subscribe(usuario => {
      let {token, ...currentUsuario} = usuario || {};
      console.log('usuario logueado', currentUsuario);
      this.logueado = usuario != null;
      if (!this.logueado) {
        console.log('revisar', this._activeRoute.snapshot.queryParams);
        // this._router.navigate(['/']);
      }
      else {
        this.actualizarDatosUsuario();
      }
    });

    this._activeRoute.queryParams.subscribe((params) => {
      console.log('query params', params);
    });

    this._activeRoute.queryParamMap
      .subscribe((params) => {
        let {token, ...currentUsuario} = this._authSvc.usuarioActual || {};
        console.log('app queryParamMap', params);
        console.log('usuario', currentUsuario);

        const jwt = params.get('jwt');
        const destino = params.get('destino');
        if (jwt) {
          if (this._authSvc.usuarioActual == null) {
            if (!this._authSvc.validarNuevoJwt(jwt)) {
              return;
            }

            console.log('jwt validado');
          }

          this.insertado = true;
          this._router.navigate([destino]).then((a) => console.log('nav ok', a)).catch((a) => console.log('nav fail', a));
      }
        /*
        const destino = params.get('destino');

        if (destino) {
          this.insertado = true;
          console.log('Modo Business One');
        }
        */
      }
    );

    let {token, ...currentUsuario} = this?._authSvc.usuarioActual  || {};
    console.log('recién iniciado', currentUsuario);

    if (idioma.base === 'es'){
      this.idioma = true;
    }else{
      this.idioma = false;
    }

    this.actualizarDatosUsuario();
  }

   actualizarDatosUsuario(): void {
      const idusuario = this._authSvc?.usuarioActual?.id;
      if (idusuario) {
        this._usuarioSvc.getById(idusuario).subscribe(
          usuario => {
            this.usuario = usuario;
          }
        );
      }
      else {
        this.usuario = null;
      }
   }

   cambiaIdioma(): void {

    $('.bandera').toggleClass('flag-hidden');

    if(this.idioma){
      this._idiomaSvc.setIdioma('es');
    }else{
      this._idiomaSvc.setIdioma('en');
    }
  }

  onLogout(): void {
    this._authSvc.logout();
  }

  collapseSidebar(){

    $('#small-sidebar').toggleClass('d-none');
    $('#sidebar').toggleClass('sidebar');
    $('#sidebar').toggleClass('sidebar-collapsed');

  }

}
