import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class BaseService<T> {

  protected headers: any;

  constructor(
    protected http: HttpClient,
    protected authService: AuthenticationService,
    protected rutaRelativa: string
  ) {

    // Para enterarme de cualquier cambio de token (previsión para renovación automática de token al expirar la sesión).
    this.authService.cambioUsuario$.subscribe(user => {
      if (user == null) {
        this.headers = {
          'content-type': 'application/json',
        };
      } else {
        this.headers = {
          'content-type': 'application/json',
          authorization: 'Bearer ' + user.token
        };
      }
    });
  }

  public getAllSorted<K extends keyof T>(campo: string = 'codigo', dir: string = 'asc', inactivos: boolean = false): Observable<T[]> {
    return this.http
    .get<T[]>(environment.apiUrl + this.rutaRelativa + '?inactivos=' + (inactivos ? '1' : '0'), { headers: this.headers })
    .pipe(
      map(items => this.ordenar(items, campo, dir))
        );
  }

  public getAll(inactivos: boolean = false): Observable<T[]> {
    return this.http.get<T[]>(environment.apiUrl + this.rutaRelativa + '?inactivos=' + (inactivos ? '1' : '0'), { headers: this.headers });
  }

  public getById(id: string | number): Observable<T> {
    return this.http.get<T>(environment.apiUrl + this.rutaRelativa + '/' + id, { headers: this.headers });
  }

  public create(item: T): Observable<T> {
    return this.http.post<T>(environment.apiUrl + this.rutaRelativa, item, {headers: this.headers});
  }

  public update(item: T): Observable<T> {
    return this.http.put<T>(environment.apiUrl + this.rutaRelativa, item, {headers: this.headers});
  }

  public destroy(id: string | number, id_usuario: string | number = null): Observable<any> {
    return this.http.delete(environment.apiUrl + this.rutaRelativa + '/' + id, { headers: this.headers });
  }

  protected filtroAURL(filtro: any): string {
    let parametros: string = '';

    // tslint:disable-next-line:forin
    for (const key in filtro) {
      let valor = filtro[key];
      if (typeof(valor) === 'boolean') {
        valor = valor ? '1' : '0';
      }

      if (parametros == '') {
        parametros = '?';
      } else {
        parametros = parametros + '&';
      }

      parametros = parametros + key + '=' + encodeURI(valor);
    }

    return parametros;
  }

  public getAllFiltrado(filtro: any): Observable<T[]> {
    const parametros = this.filtroAURL(filtro);
    return this.http.get<T[]>(environment.apiUrl + this.rutaRelativa + parametros, { headers: this.headers });
  }

  protected ordenar<U>(items: U[], campo: string, dir: string): U[] {
    const itemCampo = campo.split('.');

    return items.sort((a, b) => {
      // TODO: falta ver si son string hacer el toLowerCase.

        let valorA: any;
        let valorB: any;

        if (itemCampo.length > 1){

          valorA = a[itemCampo[0]][itemCampo[1]];
          valorB = b[itemCampo[0]][itemCampo[1]];
        }else{
          valorA = a[campo];
          valorB = b[campo];
        }

        if (typeof(valorA) !== 'undefined' && isNaN(valorA) && typeof(valorA.toLowerCase) === 'function') {
          valorA = valorA.toLowerCase();
        }

        if (typeof(valorA) !== 'undefined' && isNaN(valorB) && typeof(valorB.toLowerCase) === 'function') {
          valorB = valorB.toLowerCase();
        }

        if (valorA < valorB) {
          return dir === 'desc' ? 1 : -1;
        }

        if (valorA > valorB) {
          return dir === 'desc' ? -1 : 1;
        }

        return 0;
      });
  }
}
