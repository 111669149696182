<!-- <a [routerLink]="[indicador.ruta, indicador.parametro]" style="text-decoration: none;" class="card card-indicador"> -->
<div class="card card-indicador">
  <!-- <canvas id="myChart" width="520" height="300"></canvas> -->

    <highcharts-chart *ngIf="highcharts; else loading"
      [Highcharts] = "highcharts"
      [options] = "chartOptions"
      style="width: 100%; height: 300px; display: block;"
    >

  </highcharts-chart>
</div>

<!-- </a> -->

<ng-template class="text-center" #loading>
  <div class="d-flex justify-content-center align-items-center p-5 w-100 h-100">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>
</ng-template>

<!-- {{indicador | json}} -->

