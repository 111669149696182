import { AuthenticationService } from './../services/authentication.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import { Router } from '@angular/router';

import { IdiomaService } from '../services/idioma.service';
import { Observable } from 'rxjs';

import { UsuarioService } from '../backoffice/services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ UsuarioService ]
})
export class LoginComponent implements OnInit {

  public t: any;
  public textos$: Observable<any>;
  public loginForm = new FormGroup({
    nombre: new FormControl(''),
    password: new FormControl('')
  });

  public errorMsg = '';

  @Output() logueado = new EventEmitter<boolean>();

  constructor(
    private _usuarioSvc: UsuarioService,
    private _authSvc: AuthenticationService,
    private _router: Router,
    private _idioma: IdiomaService
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => this.t = texto);
  }

  onSubmit(): void {
    const nombre: string = this.loginForm.value.nombre;
    const password: string = this.loginForm.value.password;

    if (!nombre || nombre === '' || ! password || password === ''){
      this.errorMsg = this.t.todosdatosrequeridos;
    } else {
      const data = { nombre, password };
      this._usuarioSvc.login(data).subscribe(
        resp => {
          const jwt: string = resp;
          if (this._authSvc.validarNuevoJwt(jwt)) {
            this.loginForm.reset();
            this.errorMsg = '';
            this.logueado.emit(true); // Esto no debería hacer falta, se puede suscribir al obserbable del authService.
            console.log('al home');
            this._router.navigate(['/inicio']);
          } else {
            this.errorMsg = this.t.datosincorrectos;
          }
        }, error => {
          console.log(error);
        }
      );
    }
  }
}
