
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'orderBase'
})
export class OrderBasePipe implements PipeTransform {

  transform(value: any[], order: any = '', column: any = '' ): any[] {

    if (!value || order === '' || !order) { return value; } // no array

    if (value.length <= 1) { return value; } // array with only one item

    if (column && column != '') {

      if(order==='asc'){
          value.sort((a, b) => {

            var A = a[column];
            var B = b[column];

            if(A>B){
                return 1;
            }
            if(B>A){
                return -1
            }
            return 0
          })


      }else{
        value.sort((a, b) => {
            var A = a[column];
            var B = b[column];

            if(A>B){
                return -1;
            }
            if(B>A){
                return 1
            }
            return 0
        })

      }
        return value;
    }

  }

}
