import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXT = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExportExcelService {

  constructor(){}

  public exportAsExcelFile(
    reportHeading:string,
    reportSubHeading: string,
    headersArray: any[],
    json: any[],
    footerData:any,
    excelFileName: string,
    sheetName: string,
  ){
    const header = headersArray;
    const data = json;

    const workbook: Workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(sheetName);
    worksheet.addRow([]);

    //Add Headers Row
    worksheet.addRow([]);
    worksheet.mergeCells('A2:' + this.numToAlpha(header.length - 1) + '2');
    worksheet.getCell('A2').value = reportHeading;
    worksheet.getCell('A2').alignment = { horizontal: 'center'};
    worksheet.getCell('A2').font = { size: 15, bold: true };

    if(reportSubHeading != ''){
      worksheet.addRow([]);
      worksheet.mergeCells('A3:' + this.numToAlpha(header.length - 1) + '3');
      worksheet.getCell('A3').value = reportSubHeading;
      worksheet.getCell('A3').alignment = { horizontal: 'center'};
      worksheet.getCell('A3').font = { size: 12, bold: false };
    }


    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'2A4A82'},
      };
      cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { size: 14, bold: true, color: {'argb': 'FFFFFF'} };
      cell.alignment = { horizontal: 'center', vertical: 'middle'}

      worksheet.getColumn(index).width = header[index-1].length < 20 ? 20 : header[index-1].length;

    });

    // Get all columns from json
    let columnsArray: any[];
    for(const key in json){
      if(json.hasOwnProperty(key)){
        columnsArray = Object.keys(json[key]);
      }
    }


    // Add Data and conditional formating
    data.forEach( ( element: any ) => {
      const eachRow = [];
      columnsArray.forEach((column) => {
        eachRow.push(element[column]);
      })

      worksheet.addRow(eachRow);
    })



    worksheet.addRow([]);

    if(footerData != null){
      footerData.forEach((element: any) => {
          const eachRow = [];
          element.forEach((val: any) => {
            eachRow.push(val);
          });

          const footerRow = worksheet.addRow(eachRow);
          footerRow.eachCell(cell => {
            cell.font = { bold: true}
          });
      });
    }

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXT);
    });

  }

  private numToAlpha(num: number) {
    let alpha = '';

    for(; num >= 0; num = parseInt((num/26).toString(), 10) -1){
      alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
    }
    return alpha;
  }
}
