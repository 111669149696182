import { IdiomaService } from './idioma.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthenticationService,
      protected _toastr: ToastrService,
      private _idioma: IdiomaService,
      ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
              // auto logout if 401 response returned from api
              console.log('saliendo 401');
              this.authenticationService.logout();
            }

            // El token inválido se informa como HTTP 403.
            if (err.status === 403 && err?.error?.message === 'bad token') {
              console.log('saliendo 403');
              this.authenticationService.logout();
            }

            let error = err.error || err.statusText;

            // Buscar mensaje de error por idioma.
            if (err.error.key || err.error.mensaje) {
              let msg = null;

              if (err.error.key) {
                this._idioma.base = request.body?.idioma || this._idioma.base // Establezco el idioma del mensaje de error
                msg = this._idioma.getTexto(err.error.key.toLowerCase());
              }

              if(err.error.datos){
                msg = `${msg} ${err.error.mensaje}`;
              }

              if (!msg) {
                msg = err.error.mensaje || err.error.key.toLowerCase();
              }

              error = msg;
            }

            if (error instanceof ProgressEvent) {
              error = 'Error de conexión';
            }

            if (error instanceof Blob && err.status === 404) {
              error = 'Archivo no encontrado';
            }

            this._toastr.error(error);

            return throwError(error);
        }));
    }
}
