import { LoginComponent } from './login/login.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { PortadaComponent } from './portada/portada.component';

/* Guards */
import { LoggedUserGuard } from './guards/logged-user.guard';
import { AdminUserGuard } from './guards/admin-user.guard';
import { ABMGeneralUserGuard } from './guards/abm-general-user.guard';

const routes: Routes = [
  { path: 'inicio', component: PortadaComponent },
  {
    path: 'backoffice',
    canActivate: [LoggedUserGuard, AdminUserGuard],
    loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)
  },
  {
    path: 'general',
    canActivate: [LoggedUserGuard, ABMGeneralUserGuard],
    loadChildren: () => import('./general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'changepass',
    canActivate: [LoggedUserGuard],
    component: ChangepassComponent
  },
  {
    path: 'parte',
    canActivate: [LoggedUserGuard],
    loadChildren: () => import('./partediario/partediario.module').then(m => m.PartediarioModule)
  },
  {
    path: 'check',
    canActivate: [LoggedUserGuard],
    loadChildren: () => import('./checklist/checklist.module').then(m => m.ChecklistModule)
  },
  {
    path: 'mantenimiento',
    canActivate: [LoggedUserGuard],
    loadChildren: () => import('./mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule)
  },
  {
    path: 'controldocumental',
    canActivate: [LoggedUserGuard],
    loadChildren: () => import('./controldocumental/controldocumental.module').then(m => m.ControldocumentalModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
