

export const links = [

    {modulo:'general',
    icono:'far fa-folder',
    enlaces:[
        { ruta: './general/articulo', item:'marticulo', icono:'fa fa-minus'},
        { ruta: './general/grupo-articulo', item:'mgrupoarticulo', icono:'fa fa-minus'},
        { ruta: './general/socio-negocio', item:'msocionegocio', icono:'fa fa-minus'},
        { ruta: './general/tipo-socio-negocio', item:'mtiposocionegocio', icono:'fa fa-minus'},
        { ruta: './general/empleado', item:'mempleado', icono:'fa fa-minus'},
        { ruta: './general/ubicacion', item:'mubicacion', icono:'fa fa-minus'},
        { ruta: './general/socio-negocio-ubicacion', item:'msocionegocioubicacion', icono:'fa fa-minus'},
        { ruta: './general/herramienta', item:'mherramienta', icono:'fa fa-minus'},
    ]}
]

