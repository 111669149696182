import { Observable } from 'rxjs';
import { Indicador } from '../models/indicador';
import { Component, Input, OnInit } from '@angular/core';
// import Chart from 'chart.js/auto';
import * as Highcharts from 'highcharts';
import { style } from '@angular/animations';
import { IdiomaService } from 'src/app/services/idioma.service';
@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css'],
})
export class GraficoComponent implements OnInit {
  @Input() public codigo: string;

  @Input() public indicador: Indicador;

  private datos: any[];

  highcharts: typeof Highcharts;
  chartOptions: any;

  private tipo: string;

  public t: any;
  public textos$: Observable<any>;

  constructor(
    private _idioma: IdiomaService,
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });

    if(this.indicador.valor.length > 0 ){
      this.datos = this.indicador.valor;
    } else {
      this.datos = [{name: this.t.sinelementos, y:1}]
    }


    this.getDatosGrafico();
  }

  private getDatosGrafico(): void {

    this.highcharts = Highcharts;

    switch (this.indicador.tipo) {
      case 'barra':
        this.getBarChartOptions();
        break;
      case 'torta':
        this.getPieChartOptions();
        break;

      default:
        break;
    };

  }

  private getPieChartOptions() {

    this.chartOptions = {
      chart: {
        backgroundColor: 'none',
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: this.t[this.indicador.nombre],
        style: { "fontSize": "15px", "fontFamily": "Open Sans", "fontWeight":"bold"},
        align: 'left'
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },

      colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
        return {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.3).get('rgb')], // darken
          ],
        };
      }),
      series: [
        {
          type: 'pie',
          // name: 'Browser share',
          data: this.datos,
        },
      ],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            connectorColor: 'silver',
          },
        },
      },
    };
  }

  private getBarChartOptions() {

    let categorias = this.datos.map(item => item.name);
    let valores = this.datos.map(item => item.y);
    console.log('datos', this.datos);
    console.log('categorias', categorias);
    console.log('valores', valores);

    let colores = ["#7cb5ec", "#2b908f","#434348","#f15c80","#2100D6",  "#90ed7d", "#f7a35c", "#8085e9", "#e4d354", "#f45b5b", "#91e8e1"]

    this.chartOptions =  {
      chart: {
        backgroundColor: 'none',
        type: 'column'
      },
      title: {
          text: this.t[this.indicador.nombre],
          align: 'left',
          style: { "fontSize": "15px", "fontFamily": "Open Sans", "fontWeight":"bold"}
      },
      // subtitle: {
      //     text: 'Source: WorldClimate.com'
      // },
      xAxis: {
          categories: categorias,
          crosshair: true,
          title:{
            text: this.t[this.indicador.tituloEjeX]
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: this.t[this.indicador.tituloEjeY]
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0,
          }
      },
      series: [{
          name: '',
          data: valores

      }],
      colors: Highcharts.map(colores, function (color) {
        return {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.3).get('rgb')], // darken
          ],
        };
      }),
  }
  };

  private getRandomColor() {
    return `rgb(${this.getValorColor()}, ${this.getValorColor()}, ${this.getValorColor()} )`;
  }
  private getValorColor() {
    let max = 255;
    let min = 0;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
