<a [routerLink]="ruta" style="text-decoration: none;" class="card card-indicador">
  <div class="card-indicador-titulo">{{nombre}}</div>
  <div class="card-indicador-valor">
    <i class="fa fa-caret-right" ></i>
    <span *ngIf="indicador.unidadMedida == 'pesos'" style="font-size: 1.7rem;">${{indicador.valor | formatNumber }}</span>
    <span *ngIf="indicador.unidadMedida != 'pesos'">{{indicador.valor}}</span>

    <span class="card-indicador-valor-after">{{unidadMedida}}</span>
  </div>
</a>
<!-- {{indicador | json}} -->
