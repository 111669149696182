import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-texto-filtro',
  templateUrl: './texto-filtro.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextoFiltroComponent),
      multi: true
    }
  ]
})
export class TextoFiltroComponent implements OnInit, ControlValueAccessor {

  constructor() {
    this.control = new FormControl();
  }

  control: FormControl;

  ngOnInit(): void {
  }

  @Input()
  public get value(): string | null {
    return this.control.value;
  }

  public set value(valor: string | null) {
    this.control.setValue(valor);
  }

  onChange = (_: any) => { };
  onTouched = () => {};

  //////////////////////////////////
  // ControlValueAccessor interface
  //////////////////////////////////

  writeValue(opt: string | null): void {
    this.value = opt;
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
