import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, forwardRef, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Observable } from 'rxjs';
import { AdjuntoService } from 'src/app/services/adjunto.service';
import { IdiomaService } from 'src/app/services/idioma.service';
import { Adjunto, AdjuntoSeleccionado } from 'src/app/mantenimiento/models/adjunto';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];

  @ViewChild('fileSelector', { static: true })
  public fileSelector: ElementRef;

  @Output() archivoSeleccionado = new EventEmitter<AdjuntoSeleccionado>();

  // textos
  public t: any;
  public textos$: Observable<any>;

  @Input() leyenda: string = 'arrastrarysoltar';

  constructor(
    protected http: HttpClient,
    private _idioma: IdiomaService,
    private _adjunto: AdjuntoService
  ) {
  }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });
  }

  public guardarLosArchivos(modulo: string, id_objeto: string, adjuntos: Adjunto[]): void {
    for (const adjunto of adjuntos) {
      const item = this.files.filter(a => a.relativePath === adjunto.nombre);
      if (item.length > 0 && (!adjunto.id || adjunto.id == 0)) {
        const fileEntry = item[0].fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          this._adjunto.create(modulo, id_objeto, item[0].relativePath, file).subscribe(res => {
            // console.log('archivo subido', adjunto.nombre);
          });
        });
      }
    }
  }

  public openFileSelector = (event?: MouseEvent): void => {
    if (this.fileSelector && this.fileSelector.nativeElement) {
      (this.fileSelector.nativeElement as HTMLInputElement).click();
    }
  }

  public dropped(files: NgxFileDropEntry[]): void {

    for (const droppedFile of files) {
      // Ignorar lo que no es archivo.
      if (!droppedFile.fileEntry.isFile) {
        continue;
      }

      // Quitar posible repetido.
      this.files = this.files.filter(a => a.relativePath !== droppedFile.relativePath);
      this.files.push(droppedFile);
      this.archivoSeleccionado.emit({
        nombre: droppedFile.relativePath
      });
    }
  }

  public obtenerArchivo(nombre: string) : any{
    //console.log(this.files)
    return this.files.filter(a => a.relativePath === nombre)[0];
  }
}
