import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {idioma} from './textos';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {

  public base: string;

  private textos$ = new BehaviorSubject<any>('');

  constructor() {
    this.base = idioma.base;
    if(this.base == "es"){
      this.textos$.next(idioma.es);

    }else{
      this.textos$.next(idioma.en);

    }

  }

  setIdioma(opcion: string){

    this.base = opcion;

    if(this.base == "es"){
      this.textos$.next(idioma.es);
    }else{
      this.textos$.next(idioma.en);
    }
  }

  getTextos$(): Observable<any>{
    return this.textos$.asObservable();
  }

  getTexto(clave: string): string {
    if(this.base === 'es') {
      return idioma.es[clave];
    }

    return idioma.en[clave];
  }
}
