import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IdiomaService } from 'src/app/services/idioma.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() ruta: string;
  @Input() id: number;
  @Input() botonEditar: boolean = true;
  @Input() botonEliminar: boolean = true;
  @Input() botonCrear: boolean = true;

  @Output() borrar: EventEmitter<boolean> = new EventEmitter();


  public t: any;
  public textos$: Observable<any>;

  constructor(
    private _idioma: IdiomaService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.textos$ = this._idioma.getTextos$();
    this.textos$.subscribe(texto => {
      this.t = texto;
    });

  }

  onCreate(): void{
    this._router.navigate([`${this.ruta}/create`]);
  }

  onEdit(): void{
    if (this.id){
      const ide = this.id;
      this.id = null;
      this._router.navigate([`${this.ruta}/edit`, ide]);
    }
  }

  onDelete(): void {
    if (this.id){
      this.borrar.emit(true);
    }
  }
}
