import { Observable } from 'rxjs';
import { BaseService } from './../../services/baseService';
import { Component, OnInit, Input, forwardRef, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModeloBase } from 'src/app/abm/models/modelobase';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true
    }
  ]
})
export class SelectorComponent implements OnInit, ControlValueAccessor {

  @Input() required: boolean;

  @Input() servicio: BaseService<ModeloBase>;

  @Input() inactivos: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inactivos || changes.servicio) {
      this.items$ = this.servicio.getAllSorted('codigo', '',this.inactivos)
    }
  }

  @Input()
  get disabled(): boolean { return this.control.disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    }
    else {
      this.control.enable();
    }
  }

  items$: Observable<ModeloBase[]>;

  control: FormControl;

  constructor() {
    this.control = new FormControl();
  }

  ngOnInit(): void {
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  public get value(): string | null {
    return this.control.value;
  }

  public set value(valor: string | null) {
    this.control.setValue(valor);
  }

  //////////////////////////////////
  // ControlValueAccessor interface
  //////////////////////////////////

  writeValue(opt: string | null): void {
    this.control.setValue(opt);
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
