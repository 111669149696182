<div class="container">
    <h3 class="main-title">{{title}}</h3>
</div>

<div class="container">
  <div class="row row-cols-1 row-cols-md-2" id="card-container">
    <a [routerLink]="['/parte/dash']" *ngIf="_authSvc.tienePermiso('parte_abm', 'parte_ver', 'parte_crear')">
      <div class="col mb-4">
        <div class="card" id="parte-logo">
          <img src="assets/images/complete-logos/Logo-PARTOne-fondo-claro-completo.png" class="card-img-top" alt="Logo PartOne">
          <div class="card-body">
            <h5 class="card-title">{{t.partediario.toUpperCase()}}</h5>
            <p class="card-text">Registro de las actividades en campo de cada operario en aplicación móvil. Transmisión a base por conexión de celular o internet.</p>
          </div>
        </div>
      </div>
    </a>
    <a [routerLink]="['/controldocumental/dash']" *ngIf="_authSvc.tienePermiso('docu_abm', 'docu_ver', 'docu_crear')">
      <div class="col mb-4">
        <div class="card" id="control-logo">
          <img src="assets/images/complete-logos/Logo-DOCOne-fondo-claro-completo.png" class="card-img-top" alt="logo CheckOne">
          <div class="card-body">
            <h5 class="card-title">{{t.controldocumental.toUpperCase()}}</h5>
            <p class="card-text">Registro y alertas vinculados a la documentación de los recursos (personal y equipos) para habilitar su ingreso a yacimientos.</p>
          </div>
        </div>
      </div>
    </a>
    <a [routerLink]="['/check/dash']" *ngIf="_authSvc.tienePermiso('check_abm', 'check_ver', 'check_crear')">
      <div class="col mb-4">
        <div class="card" id="check-logo">
          <img src="assets/images/complete-logos/Logo-CHECKOne-fondo-claro-completo.png" class="card-img-top" alt="logo CheckOne">
          <div class="card-body">
            <h5 class="card-title">{{t.checklist.toUpperCase()}}</h5>
            <p class="card-text">Gestión de check list en aplicación móvil, para registrar estado de equipos, maquinarias, instalaciones. Flexibilidad en el diseño y configuración de los distintos modelos de chequeo.</p>
          </div>
        </div>
      </div>
    </a>

    <a [routerLink]="['/mantenimiento/dash']" *ngIf="_authSvc.tienePermiso('mant_ver_sol', 'mant_crear_sol', 'mant_ver_ot', 'mant_crear_ot', 'mant_vista_plan', 'mant_vista_solic', 'mant_vista_seguim', 'mant_ver_estado', 'mant_crear_medicion')">
      <div class="col mb-4">
        <div class="card" id="eam-logo">
          <img src="assets/images/complete-logos/Logo-EAMOne-fondo-claro-completo.png" class="card-img-top" alt="logo EamOne">
          <div class="card-body">
            <h5 class="card-title">{{t.mantenimiento.toUpperCase()}}</h5>
            <p class="card-text">Gestión completa del mantenimiento preventivo y correctivo. Planes de Mantenimiento. Ordenes de Trabajo. Soporte dispositivos móviles. Acceso a indicadores e historial de cada equipo.</p>
          </div>
        </div>
      </div>
    </a>


  </div>

  <div class="row row-cols-1 row-cols-md-2" id="card-config-container">
    <a [routerLink]="['/general/socio-negocio']" *ngIf="_authSvc.tienePermiso('general_abm')">
      <div class="col mb-4">
        <div class="card mb-3" style="max-width: 540px;">
          <div class="row no-gutters">
            <div class="col-xs-4">
              <img src="assets/images/logo-general-cog.png" class="card-img" alt="Logo configuración general">
            </div>
            <div class="col-xs-8">
              <div class="card-body">
                <h5 class="card-title">{{t.general.toUpperCase()}}</h5>
                <p class="card-text">Configuración general del Sistema</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </a>

    <a [routerLink]="['/backoffice/inquilinos']" *ngIf="_authSvc.tienePermiso('admin_abm')">
      <div class="col mb-4">
        <div class="card mb-3" style="max-width: 540px;">
          <div class="row no-gutters">
            <div class="col-xs-4">
              <img src="assets/images/logo-backoffice.png" class="card-img" alt="Logo backoffice">
            </div>
            <div class="col-xs-8">
              <div class="card-body">
                <h5 class="card-title">{{t.backoffice.toUpperCase()}}</h5>
                <p class="card-text">Carga de Datos Maestros del sistema</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>


</div>
