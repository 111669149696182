
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  obtenerValor(v: any, campo: string): any {
    let resultado = v;

    const partes = campo.split('.');

    for (const p of partes) {
      if (typeof(resultado) === 'undefined' || resultado === null) {
        break;
      }

      resultado = resultado[p];
    }

    if (typeof(resultado) === 'undefined' || resultado === null) {
      resultado = '';
    }

    if (isNaN(resultado) && typeof(resultado.toLowerCase) === 'function') {
      resultado = resultado.toLowerCase();
    }

    return resultado;
  }

  transform(value: any[], order: any = '', column: any = '' ): any[] {

    if (!value || order === '' || !order) { return value; } // no array

    if (value.length <= 1) { return value; } // array with only one item

    if (column && column != '') {

      let orden = -1;
      if (order === 'asc') { orden = 1; }

      value.sort((a, b) => {

        const A = this.obtenerValor(a, column) || '';
        const B = this.obtenerValor(b, column) || '';

        if ( A > B ) {
          return orden;
        }

        if ( B > A ) {
          return -orden;
        }

        return 0;
      });
    }

    return value;
  }

}
