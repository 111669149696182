<div class="form-group row" *ngIf="(item.get('tipo').value == 'char' || item.get('tipo').value == 'text') ">
    <label for="{{item.get('nombre').value + itemName}}" class="col-md-4 col-form-label">{{item.get('nombre').value}} {{item.get('obligatorio').value ? '*': ''}}</label>
    <div [formGroup]="item" class="col-md-8">
        <input type="text" class="form-control form-control-sm" id="{{item.get('nombre').value + itemName}}" formControlName="valor" >
    </div>
</div>

<div class="form-group row" *ngIf="item.get('tipo').value == 'number'" >
    <label for="{{item.get('nombre').value + itemName}}" class="col-md-4 col-form-label">{{item.get('nombre').value}} {{item.get('obligatorio').value ? '*': ''}}</label>
    <div [formGroup]="item" class="col-md-8">
        <input type="number" class="form-control form-control-sm" id="{{item.get('nombre').value + itemName}}" formControlName="valor" >
    </div>
</div>

<div class="form-group row" *ngIf="item.get('tipo').value == 'date'" >
    <label for="{{item.get('nombre').value + itemName}}" class="col-md-4 col-form-label">{{item.get('nombre').value}} {{item.get('obligatorio').value ? '*': ''}}</label>
    <div [formGroup]="item" class="col-md-8">
        <input type="date" class="form-control form-control-sm" id="{{item.get('nombre').value + itemName}}" formControlName="valor" >
    </div>
</div>

<div class="form-group row" *ngIf="item.get('tipo').value == 'boolean'" >
    <label for="{{item.get('nombre').value + itemName}}" class="col-md-5 col-form-label">{{item.get('nombre').value}}</label>
    <div [formGroup]="item" class="col-md-7 form-checformGroupName">
        <input type="radio" class="form-checformGroupName-input" id="{{item.get('nombre').value + itemName + '1'}}" formControlName="valor" value="true">
        <label for="{{item.get('nombre').value + itemName + '1'}}" class="col-md-3 col-form-label form-checformGroupName-label">{{t.si}}</label>
        <input type="radio" class="form-checformGroupName-input" id="{{item.get('nombre').value + itemName + '2'}}" formControlName="valor" value="false">
        <label for="{{item.get('nombre').value + itemName + '2'}}" class="col-md-3 col-form-label form-checformGroupName-label">{{t.no}}</label>
    </div>
</div>

<!-- <div class="form-group row" *ngIf="item.get('tipo').value == 'table_generic'" >
    <label for="{{item.get('nombre').value + itemName}}" class="col-md-4 col-form-label">{{item.get('nombre').value}}</label>
    <div class="col-md-8" [formGroup]="item">
      <select class="form-control form-control-sm" formControlName="valor" id="{{item.get('nombre').value + itemName}}">
        <option value="">{{t.selecionaritem}}...</option>
        <option value="{{vt.codigo}}" *ngFor="let vt of item.get('valores_tabla').value">{{vt.codigo}}: {{vt.nombre}}</option>
      </select>
    </div>
</div> -->

<div class="form-group row" *ngIf="item.get('tipo').value == 'table_generic'" >
  <label for="{{item.get('nombre').value + itemName}}" class="col-md-4 col-form-label">{{item.get('nombre').value}}</label>
  <div class="col-md-8" [formGroup]="item">
    <app-buscador [bindValue] = "'codigo'" formControlName="valor" [datos] = "item.get('valores_tabla').value">
    </app-buscador>
  </div>
</div>

