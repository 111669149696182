<div *ngIf="items.controls.length" class="container my-2">
  <div>
    <span class="dropdown-toggle"  (click)="toggleCollapse()">{{group.nombre}}</span>
  </div>
  <div [collapse]="isCollapsed">
    <ng-container *ngFor="let item of items.controls let index = index">
      <ng-container *ngIf="item.get('grupo').value == group.id">

        <app-formgroup [item] = "item" [itemName] = "index" class="col-12 col-md-6"></app-formgroup>

      </ng-container>
    </ng-container>
  </div>
</div>
