<div class="login-container">
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="login-form-container">
            <div class="login-form-inner-container">
                <div class="login-form-logo">
                    <img src="assets/images/oilone.svg" alt="Logo de OilOne">
                </div>
                <div class="login-form-divider"></div>
                <div class="login-form-content">
                    <div class="login-form-image">
                        <img src="assets/images/login-img.jpg" class="img-fluid" alt="Imagen yacimiento">
                    </div>
                    <div class="login-form-data">
                        <div class="alert alert-danger" *ngIf="errorMsg">
                            {{errorMsg}}
                        </div>
                        <form [formGroup]="loginForm" (submit)="onSubmit()">
                            <div class="login-form-group d-flex justify-content-between flex-wrap align-items-center">
                                <!-- <label for="nombre">{{t.usuario}}</label> -->
                                <input id="nombre" class="form-control" placeholder="{{t.usuario}}..." type="Text" formControlName="nombre" required >
                            </div>

                            <div class="login-form-group d-flex justify-content-between flex-wrap align-items-center">
                                <!-- <label for="password">{{t.contrasena}}</label> -->
                                <input type="Password" class="form-control" placeholder="{{t.contrasena}}..." id="password" formControlName="password" required >
                            </div>

                            <div class="login-form-group-btn text-right">
                                <button type="submit" class="btn btn-outline-warning text-dark" [disabled]="!loginForm.valid" icon="login">{{t.ingresar}}</button>

                            </div>
                        </form>
<!--                         {{loginForm.value | json}} -->

                    </div>
                </div>

                <div class="login-form-footer mr-3 d-flex justify-content-end align-items-center mt-3 mb-3">
                    <small class="mr-5">Copyright@ 2020 OilOne. {{t.derechos}}</small>
                    <img src="assets/images/logo-sap-150x77.png" alt="Logo SAP" width="60">
                </div>
            </div><!-- End Login-form-inner-container -->

        </div>

    </div>


</div>
